import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useResponsive } from 'utils';

import ClaimDetailsHeader from './ClaimDetailsHeader';
import ClaimInformation from './ClaimInformation';
import { Row, ExpansionPanel, Typography } from '../alf-design';
import './ClaimDetails.css';

const ClaimDetails = ({ claim }) => {
  const { screenIsDown } = useResponsive();

  return screenIsDown('lg') ? (
    <div className="claim-details-small">
      <Row gutter={16}>
        <hr />
        <ExpansionPanel
          className="claim-details"
          key={claim.claimNumber}
          header={<ClaimDetailsHeader claim={claim} />}
          claim
        >
          <div className="claim-details-child">
            <Row>
              <Typography
                type="caption2"
                component="span"
                className="claim-details-title"
              >
                Claim details
              </Typography>
              <hr />
              <ClaimInformation claim={claim} />
            </Row>
          </div>
        </ExpansionPanel>
      </Row>
    </div>
  ) : (
    <Row gutter={16}>
      <hr />
      <div className="claim-details-big">
        <ExpansionPanel
          className="claim-details"
          key={claim.claimNumber}
          header={<ClaimDetailsHeader claim={claim} />}
          claim
        >
          <div className="claim-details-child">
            <Row>
              <Typography
                type="caption2"
                component="span"
                className="claim-details-title"
              >
                Claim details
              </Typography>
              <hr />
              <ClaimInformation claim={claim} />
            </Row>
          </div>
        </ExpansionPanel>
      </div>
    </Row>
  );
};

ClaimDetails.propTypes = {
  claim: PropTypes.object,
};

export default withRouter(ClaimDetails);
