import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Typography from '../Typography';
import './Checkbox.css';

const Checkbox = React.forwardRef(
  (
    {
      className,
      id,
      name,
      label,
      onChange,
      onFocus,
      onBlur,
      checked = false,
      disabled = false,
      style,
    },
    ref,
  ) => {
    const handleChange = (e) => {
      e.preventDefault();
      if (disabled === false) {
        const res = {
          ...e,
          target: {
            name,
            value: !checked,
          },
        };
        onChange(res);
      }
    };
    return (
      <div
        className={clsx('alf-checkbox', className, disabled && 'disabled')}
        style={style}
      >
        <input
          id={id}
          type="checkbox"
          name={name}
          onChange={handleChange}
          onKeyDown={(event) => {
            if ((event.key === 'Enter' || event.key === ' ') && handleChange) {
              handleChange(event);
            }
          }}
          value={name}
          checked={checked}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          ref={ref}
        />
        <span id="checkmark" className="checkmark" />
        <Typography
          type="caption1"
          component="label"
          className="wrapper"
          onClick={handleChange}
          htmlFor={id}
        >
          {label}
        </Typography>
      </div>
    );
  },
);

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  style: PropTypes.object,
};

export default Checkbox;
