import React from 'react';

import Typography from '../Typography';
import Icon from '../Icon';

const UploadAttachments = () => (
  <>
    <div
      style={{
        paddingTop: 45,
        paddingBottom: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Icon name="plusCircle" fontSize={50} color="#6648DC" />
      <Typography type="caption3" component="p">
        Upload
      </Typography>
    </div>
  </>
);

export default UploadAttachments;
