import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Pagination from './Pagination';
import { Typography, Spinner } from '..';

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  body: {
    marginTop: 24,
    width: 160,
  },
  th: {
    backgroundColor: '#DBDBDB',
    textAlign: 'left',
    verticalAlign: 'middle',
    padding: '12px 20px',
  },
  td: {
    verticalAlign: 'middle',
    padding: '8px 20px',
    borderTop: '1px solid #DBDBDB',
    whiteSpace: 'pre-wrap',
    fontWeight: 'unset',
    textAlign: 'unset',
  },
  thText: {
    color: '#333333',
    fontSize: 'min(16rem, calc(16px * 1.5))',
    fontWeight: 'normal',
  },
  placeholder: {
    textAlign: 'center',
    padding: '24px',
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
  },
};

function Table({
  className,
  columns,
  data,
  loading,
  dataRowId = (element) => element.id,
  titleNoWrap,
  pagination = false,
  payment = false,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  let filteredData = data.sort(function (a, b) {
    if (a.Date < b.Date) {
      return 1;
    }
    if (a.Date > b.Date) {
      return -1;
    }
    return 0;
  });

  if (pagination && data) {
    filteredData = data.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize,
    );
  }

  return (
    <div>
      <table className={clsx('alf-table', className)} style={styles.table}>
        <thead>
          <tr>
            {columns.map((c) => (
              <th key={c.key} style={{ ...styles.th, ...c.style }} scope="col">
                <Typography
                  component="span"
                  style={styles.thText}
                  wordNoWrap={titleNoWrap}
                >
                  {c.title}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="4">
                <div style={styles.circularProgress}>
                  <Spinner id="dashboard-spinner" size={80} />
                </div>
              </td>
            </tr>
          ) : data && data.length !== 0 ? (
            filteredData.map((d, i) => (
              <tr key={dataRowId(d) || i}>
                <th
                  scope="row"
                  key={columns[0].key}
                  style={{ ...styles.td, ...columns[0].style }}
                >
                  <Typography
                    wordNoWrap
                    component="span"
                    type={columns[0].typography || 'caption1'}
                  >
                    {columns[0].render
                      ? columns[0].render(d)
                      : d[columns[0].key]}
                  </Typography>
                </th>
                {columns.slice(1).map((c) => (
                  <td key={c.key} style={{ ...styles.td, ...c.style }}>
                    <Typography
                      wordNoWrap
                      component="span"
                      type={c.typography || 'caption1'}
                    >
                      {c.render ? c.render(d) : d[c.key]}
                    </Typography>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={styles.placeholder}>
                <Typography type="caption1">
                  {payment ? 'No payment history.' : 'No policy documents.'}
                </Typography>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {pagination && data && data.length > pageSize && (
        <Pagination
          total={data.length}
          onChange={setCurrentPage}
          current={currentPage}
          pageSize={pageSize}
        />
      )}
    </div>
  );
}

Table.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  className: PropTypes.object,
  dataRowId: PropTypes.func,
  titleNoWrap: PropTypes.bool,
  pagination: PropTypes.bool,
  payment: PropTypes.bool,
};

export default Table;
