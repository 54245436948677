import React from 'react';
import './PanelRight.css';
import { withRouter } from 'react-router-dom';
import { useResponsive } from 'utils';

import { Row, Col, Typography, Button } from '../alf-design';
import { homeShadow, storm } from '../../assets/icon';

const renderCircleIcon = (icon) => (
  <img alt="" src={icon} style={{ maxWidth: '100%' }} />
);
const PanelRight = () => {
  const { width } = useResponsive();
  const normalWidth = width < 1280 && width > 620;
  return (
    <div className="panel">
      <Row className="first-row">
        <Col md={3} className="item-icon">
          {renderCircleIcon(homeShadow)}
        </Col>
        <Col md={19} xl={16} className="second-row-text">
          <Typography type="heading3" component="h2" className="item-subtitle">
            Slide Resource Center
          </Typography>
          <Typography type="paragraph" component="p" className="item-paragraph">
            Visit our ever-growing collection of articles and tips to help you
            navigate the world of homeownership and insurance.
          </Typography>
          {!normalWidth && (
            <Button
              id="slide-resource-center-button"
              whiteBorder
              onClick={() =>
                window.open('https://www.slideinsurance.com/resource-center')
              }
              className="button"
              link
            >
              Learn More
            </Button>
          )}
        </Col>
        {normalWidth && (
          <Col md={24}>
            <Button
              id="slide-resource-center-button"
              whiteBorder
              onClick={() =>
                window.open('https://www.slideinsurance.com/resource-center')
              }
              className="button"
              link
            >
              Learn More
            </Button>
          </Col>
        )}
      </Row>
      <Row className="second-row">
        <Col md={3} className="item-icon">
          {renderCircleIcon(storm)}
        </Col>
        <Col md={19} xl={16} className="second-row-text">
          <Typography type="heading3" component="h2" className="item-subtitle">
            Storm Center
          </Typography>
          <Typography type="paragraph" component="p" className="item-paragraph">
            Severe weather can happen at any time. Preparation is vital, and
            it's never too early. Slide’s Storm Center is built to help you
            weather the storm before, during, and after.
          </Typography>
          {!normalWidth && (
            <Button
              id="storm-center-button"
              whiteBorder
              onClick={() =>
                window.open('https://www.slideinsurance.com/stormcenter')
              }
              className="button"
              link
            >
              Learn More
            </Button>
          )}
        </Col>
        {normalWidth && (
          <Col md={24}>
            <Button
              id="storm-center-button"
              whiteBorder
              onClick={() =>
                window.open('https://www.slideinsurance.com/stormcenter')
              }
              className="button"
              link
            >
              Learn More
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default withRouter(PanelRight);
