import { STATUS } from './constants';

const transformClaim = ({
  loss: {
    date,
    cause,
    location: {
      addressLine,
      city,
      state: { code, name },
      zipCode
    }
  },
  ...remainingProps
}) => ({
  loss: {
    date,
    cause
  },
  lossLocation: {
    street: addressLine,
    city: city,
    state: code,
    zip: zipCode
  },
  ...remainingProps
});

const transformClaims = (claims) => claims.map((c) => transformClaim(c));

const statusCode = {
  Active: STATUS.ACTIVE,
  Reinstatement: STATUS.ACTIVE,
  'Pending Cancellation': STATUS.PENDING_CANCEL,
  Cancelled: STATUS.CANCELLED,
  Cancellation: STATUS.CANCELLED
};

const transformPolicy = ({
  policyNumber,
  insuredStreet,
  insuredCity,
  insuredState,
  type,
  insuredZip,
  insuredFirstName,
  insuredLastName,
  insuredPhone,
  insuredEmail,
  effectiveDate,
  expirationDate,
  company,
  propertyStreet,
  propertyCity,
  propertyState,
  propertyZip,
  status,
  policyPremium,
  coverages,
  additionalCoverages,
  deductibles,
  agentOrganizationName,
  agentFirstName,
  agentLastName,
  agentStreet,
  agentCity,
  agentState,
  agentZip,
  agentPhone,
  agentEmail,
  country,
  payment,
  cancellationReason,
  cancellationDate,
  billing,
  policySystem,
  fnol,
  pendingEndorsement
}) => ({
  payment: payment || {},
  billing: billing || null,
  policySystem,
  country,
  policyNumber,
  status,
  statusCode: statusCode[status],
  cancellationReason,
  cancellationDate,
  price: policyPremium,
  company,
  type,
  effectiveDate,
  expirationDate,
  insured: {
    street: insuredStreet || '',
    city: insuredCity || '',
    state: insuredState || '',
    zip: insuredZip || '',
    firstName: insuredFirstName || '',
    lastName: insuredLastName || '',
    phone: insuredPhone || '',
    email: insuredEmail || ''
  },
  property: {
    street: propertyStreet || '',
    city: propertyCity || '',
    state: propertyState || '',
    zip: propertyZip || ''
  },
  agent: {
    organizationName: agentOrganizationName || '',
    firstName: agentFirstName || '',
    lastName: agentLastName || '',
    street: agentStreet || '',
    city: agentCity || '',
    state: agentState || '',
    zip: agentZip || '',
    phone: agentPhone || '',
    email: agentEmail || ''
  },
  coverages:
    coverages && coverages.length > 0
      ? coverages.sort((a, b) => (a.title > b.title ? 1 : -1))
      : [],
  additionalCoverages: additionalCoverages || [],
  deductibles: deductibles || [],
  fnol: fnol || { data: {} },
  pendingEndorsement: pendingEndorsement
});
export default {
  claim: transformClaim,
  claims: transformClaims,
  policy: transformPolicy
};
