import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Status.css';

const TYPES = ['Open', 'Closed'];

const Status = ({ id, style, className, status = 'Open' }) => {
  return (
    <div
      id={id}
      data-testid={id}
      style={style}
      className={clsx('alf-status', className, status)}
    >
      {status}
    </div>
  );
};

Status.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  status: PropTypes.oneOf(TYPES),
};

export default Status;
