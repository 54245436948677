export default [
  {
    policyNumber: '70 44 00007313 00',
    claimNumber: '202405000282',
    claimFiledDate: '2024-05-16T06:48:10.041Z',
    claimStatus: 'Open',
    claimsExaminer: {
      name: 'Joseph Duenas',
      phone: '(123) 465-7890',
      email: 'j.duenas@examiner.com'
    },
    loss: {
      date: '2024-05-16T04:01:00.000Z',
      cause: 'Appliance',
      location: {
        addressLine: '7140 FALCONS GLEN BLVD',
        city: 'NAPLES',
        state: {
          code: 'FL',
          name: 'Florida'
        },
        zipCode: '34113'
      }
    }
  },
  {
    policyNumber: '70 44 00007313 00',
    claimNumber: '202405000438',
    claimFiledDate: '2024-06-18T08:48:10.041Z',
    claimStatus: 'Closed',
    loss: {
      date: '2024-06-17T09:01:00.000Z',
      cause: 'Appliance',
      location: {
        addressLine: '7140 FALCONS GLEN BLVD',
        city: 'NAPLES',
        state: {
          code: 'FL',
          name: 'Florida'
        },
        zipCode: '34113'
      }
    }
  }
];
