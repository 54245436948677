import React from 'react';
import PropTypes from 'prop-types';
import { useResponsive } from 'utils';
import { causeloss, location } from 'assets/icon';

import { Typography, Status, Col } from '../alf-design';
import './ClaimDetailsHeader.css';

const ClaimDetailsHeader = ({ claim }) => {
  const renderCircleIcon = (icon, width) => (
    <img alt="" src={icon} className="icon" style={{ width }} />
  );
  const { screenIsDown } = useResponsive();

  return screenIsDown('lg') ? (
    <div>
      <div className="firsRow">
        <Typography type="caption3" component="h3" className="title">
          {`Claim #${claim?.claimNumber}`}
        </Typography>
        <Status id="status" status={claim?.claimStatus}>
          {claim?.claimStatus}
        </Status>
      </div>
      <div className="secondRow">
        <Col className="causeloss">
          {renderCircleIcon(causeloss, '20px')}
          <Typography type="caption1" component="span" className="subtitles">
            {claim?.loss?.cause}
          </Typography>
        </Col>
        <Col className="location">
          {renderCircleIcon(location, '20px')}
          <Typography type="caption1" component="span" className="subtitles">
            {`${claim?.lossLocation?.street}, ${claim?.lossLocation?.city} ${claim?.lossLocation?.state} ${claim?.lossLocation?.zip}`}
          </Typography>
        </Col>
      </div>
    </div>
  ) : (
    <div className="claimDetailsHeader">
      <Typography type="caption3" component="h3" className="title">
        {`Claim #${claim?.claimNumber}`}
      </Typography>
      <Status
        id="status"
        style={{ marginRight: 20 }}
        status={claim?.claimStatus}
      >
        {claim?.claimStatus}
      </Status>
      <div className="causeloss">
        {renderCircleIcon(causeloss)}
        <Typography type="caption1" component="span" className="subtitles">
          {claim?.loss?.cause}
        </Typography>
      </div>
      <div className="location">
        {renderCircleIcon(location)}
        <Typography type="caption1" component="span" className="subtitles">
          {`${claim?.lossLocation?.street}, ${claim?.lossLocation?.city} ${claim?.lossLocation?.state} ${claim?.lossLocation?.zip}`}
        </Typography>
      </div>
    </div>
  );
};

ClaimDetailsHeader.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ClaimDetailsHeader;
