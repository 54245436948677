import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import { Auth0Provider } from 'utils';
import { createRoot } from 'react-dom/client';

import ErrorBoundary from './components/ErrorBoundary';
import App from './components/App';
import config from './config';

import './index.css';
const container = document.getElementById('root');
const root = createRoot(container);

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    window.history.state,
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

if (process.env.NODE_ENV !== 'production') {
  root.render(
    <HttpsRedirect>
      <ErrorBoundary>
        <Auth0Provider
          domain={config.auth0.domain}
          domainUrl={config.auth0.domain}
          client_id={config.auth0.clientId}
          audience={config.auth0.audience}
          scope={config.auth0.scope}
          redirect_uri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
        >
          <App />
        </Auth0Provider>
      </ErrorBoundary>
    </HttpsRedirect>,
  );
}

if (process.env.NODE_ENV === 'production') {
  const pattern = new RegExp(`${config.appUrl}$`, 'i');
  if (!window.origin.match(pattern)) {
    window.location.replace(`https://${config.appUrl}`);
  } else {
    root.render(
      <HttpsRedirect>
        <ErrorBoundary>
          <Auth0Provider
            domain={config.auth0.domain}
            domainUrl={config.auth0.domain}
            client_id={config.auth0.clientId}
            audience={config.auth0.audience}
            scope={config.auth0.scope}
            redirect_uri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
          >
            <App />
          </Auth0Provider>
        </ErrorBoundary>
      </HttpsRedirect>,
    );
  }
}
