import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import './Tooltip.css';
import clsx from 'clsx';

import Button from '../Button';
import Icon from '../Icon';

const Tooltip = ({
  icon = 'infoCircle',
  iconSize = 18,
  iconColor = '#6648DC',
  display = 'inline-block',
  ariaLabel = 'Tooltip Button',
  type = 'info',
  text = '',
  dataFor = '',
  className,
  clickable = false,
}) => (
  <div
    data-tip
    data-for={`${dataFor} || tooltip`}
    style={{
      width: `${iconSize}px`,
      height: `${iconSize}px`,
      display: display,
      margin: '0px 8px',
    }}
  >
    <Button
      ariaLabel={ariaLabel}
      ariaDescribedBy="tooltip"
      id="tooltipButton"
      style={{
        display: 'inline-flex',
        verticalAlign: 'text-top',
      }}
      htmlType="button"
      icon={<Icon name={icon} fontSize={iconSize} color={iconColor} />}
    />
    <ReactTooltip
      id={`${dataFor} || tooltip`}
      place="right"
      type={type}
      effect="solid"
      textColor="#5b564d"
      backgroundColor="#ffffff"
      border
      borderColor="#5b564d"
      className={clsx(className, 'alf-tooltip')}
      clickable={clickable}
      delayHide={500}
    >
      {text}
    </ReactTooltip>
  </div>
);

Tooltip.propTypes = {
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  display: PropTypes.string,
  ariaLabel: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
  dataFor: PropTypes.string,
  clickable: PropTypes.bool,
};

export default Tooltip;
