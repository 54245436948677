export default [
  {
    policySystem: 'Duck Creek',
    status: 'In Force',
    type: 'Homeowners',
    country: 'US',
    organization: '0',
    organizationName: 'Byron  Farrell',
    effectiveDate: '2020-05-22T00:00:00',
    expirationDate: '2021-05-21T00:00:00',
    policyNumber: '70 44 00007313 00',
    policyHolderFirstName: 'Byron',
    policyHolderLastName: 'Farrell',
    policyHolderPhoneNumber: '9999999999',
    policyPremium: null,
    insuredEmail: 'ByronFarrell@gmail.com',
    insuredFirstName: 'Byron',
    insuredLastName: 'Farrell',
    insuredStreet: '507 Honeycomb Rdg',
    insuredCity: 'Austin',
    insuredState: 'TX',
    insuredZip: '78746',
    insuredPhone: '9999999999',
    propertyStreet: '507 Honeycomb Rdg',
    propertyCity: 'Austin',
    propertyState: 'TX',
    propertyZip: '78746',
    propertyCountry: 'US',
    agentOrganizationName: 'Caddyshack Insurance',
    agentFirstName: 'Agent',
    agentLastName: 'Name',
    agentStreet: '555 Greystone Cir',
    agentCity: 'Alamo',
    agentState: 'TX',
    agentZip: '78516',
    agentPhone: '9995556666',
    agentEmail: 'caddyshackInsurance@slide.com',
    company: 'Journey Insurance Company',
    billing: {
      payorPartyId: '08DB81594FAA7159',
      payor: 'Farrell',
      paymentPlan: 'QUARTERLY',
      amountPaid: 4288.0,
      paidDate: '',
      nextInstallmentAmount: 301.1,
      nextInstallmentDueDate: '2020-07-22T00:00:00',
      autoPay: false,
      paidInFull: false,
      isMortgagee: false,
      currentDueAmount: 0,
      currentDueDate: null,
      outstandingBalance: 907.5,
      pastDueAmount: 12,
      isPaperless: true
    },
    coverages: [
      {
        title: 'Coverage C - Personal Property',
        value: 300000
      },
      {
        title: 'Coverage D - Loss of Use',
        value: 180000
      },
      {
        title: 'Coverage A - Dwelling',
        value: 600000
      },
      {
        title: 'Coverage E - Personal Liability',
        value: 300000
      },
      {
        title: 'Coverage F - Medical Payments To Others',
        value: 5000
      },
      {
        title: 'Coverage B - Other Structures',
        value: 60000
      }
    ],
    deductibles: [
      {
        title: 'All Other Perils',
        value: 6000
      },
      {
        title: 'Wind/Hail',
        value: 6000
      }
    ]
  },
  {
    policySystem: 'Duck Creek',
    status: 'In Force',
    type: 'Homeowners',
    country: 'US',
    organization: '0',
    organizationName: 'Byron  Farrell',
    effectiveDate: '2020-05-22T00:00:00',
    expirationDate: '2021-05-21T00:00:00',
    policyNumber: '70 44 00007321 00',
    policyHolderFirstName: 'Byron',
    policyHolderLastName: 'Farrell',
    policyHolderPhoneNumber: '9999999999',
    policyPremium: null,
    insuredEmail: '',
    insuredFirstName: 'Byron',
    insuredLastName: 'Farrell',
    insuredStreet: '2800 W Walnut Hill Ln',
    insuredCity: 'Irving',
    insuredState: 'TX',
    insuredZip: '75038',
    insuredPhone: '9999999999',
    propertyStreet: '2800 W Walnut Hill Ln',
    propertyCity: 'Irving',
    propertyState: 'TX',
    propertyZip: '75038',
    propertyCountry: 'US',
    agentOrganizationName: 'Caddyshack Insurance',
    agentFirstName: null,
    agentLastName: null,
    agentStreet: '555 Greystone Cir',
    agentCity: 'Alamo',
    agentState: 'TX',
    agentZip: '78516',
    agentPhone: '9995556666',
    agentEmail: null,
    company: 'Journey Insurance Company',
    billing: {
      payorPartyId: '08DB81594FAA7158',
      payor: 'Farrell',
      paymentPlan: 'QUARTERLY',
      amountPaid: 1077.45,
      paidDate: '',
      nextInstallmentAmount: 75.5,
      nextInstallmentDueDate: '2020-07-22T00:00:00',
      autoPay: false,
      paidInFull: false,
      isMortgagee: false,
      currentDueAmount: 234,
      currentDueDate: '2020-06-25T00:00:00',
      outstandingBalance: 1077.45,
      pastDueAmount: null,
      isPaperless: false
    },
    coverages: [
      {
        title: 'Coverage C - Personal Property',
        value: 80500
      },
      {
        title: 'Coverage D - Loss of Use',
        value: 48300
      },
      {
        title: 'Coverage A - Dwelling',
        value: 161000
      },
      {
        title: 'Coverage E - Personal Liability',
        value: 300000
      },
      {
        title: 'Coverage F - Medical Payments To Others',
        value: 5000
      },
      {
        title: 'Coverage B - Other Structures',
        value: 16100
      }
    ],
    deductibles: [
      {
        title: 'All Other Perils',
        value: 1610
      },
      {
        title: 'Wind/Hail',
        value: 3220
      }
    ]
  },
  {
    policySystem: 'Duck Creek',
    status: 'In Force',
    type: 'Homeowners',
    country: 'US',
    organization: '0',
    organizationName: 'Byron  Farrell',
    effectiveDate: '2020-05-22T00:00:00',
    expirationDate: '2021-05-21T00:00:00',
    policyNumber: '70 44 00007339 00',
    policyHolderFirstName: 'Byron',
    policyHolderLastName: 'Farrell',
    policyHolderPhoneNumber: '9999999999',
    policyPremium: null,
    insuredEmail: 'ByronFarrell@gmail.com',
    insuredFirstName: 'Byron',
    insuredLastName: 'Farrell',
    insuredStreet: '8249 Irish Dr',
    insuredCity: 'North Richland Hills',
    insuredState: 'TX',
    insuredZip: '76180',
    insuredPhone: '9999999999',
    propertyStreet: '8249 Irish Dr',
    propertyCity: 'North Richland Hills',
    propertyState: 'TX',
    propertyZip: '76180',
    propertyCountry: 'US',
    agentOrganizationName: 'Caddyshack Insurance',
    agentFirstName: null,
    agentLastName: null,
    agentStreet: '555 Greystone Cir',
    agentCity: 'Alamo',
    agentState: 'TX',
    agentZip: '78516',
    agentPhone: '9995556666',
    agentEmail: null,
    company: 'Journey Insurance Company',
    billing: {
      payorPartyId: '08DB81594FAA7157',
      payor: 'Farrell',
      paymentPlan: 'QUARTERLY',
      amountPaid: 1616.67,
      paidDate: '',
      nextInstallmentAmount: 540.89,
      nextInstallmentDueDate: '2020-08-22T00:00:00',
      autoPay: false,
      paidInFull: false,
      isMortgagee: false,
      currentDueAmount: 345,
      currentDueDate: '2020-07-01T00:00:00',
      outstandingBalance: 1616.67,
      pastDueAmount: 23,
      isPaperless: false
    },
    coverages: [
      {
        title: 'Coverage C - Personal Property',
        value: 107000
      },
      {
        title: 'Coverage D - Loss of Use',
        value: 64200
      },
      {
        title: 'Coverage A - Dwelling',
        value: 214000
      },
      {
        title: 'Coverage E - Personal Liability',
        value: 300000
      },
      {
        title: 'Coverage F - Medical Payments To Others',
        value: 5000
      },
      {
        title: 'Coverage B - Other Structures',
        value: 21400
      }
    ],
    deductibles: [
      {
        title: 'All Other Perils',
        value: 2140
      },
      {
        title: 'Wind/Hail',
        value: 4280
      }
    ]
  }
];
