import React from 'react';
import PropTypes from 'prop-types';
import * as mdIcons from 'react-icons/md';
import clsx from 'clsx';

import { fontAwesome } from '../../../assets/icon';

import './Icons.css';

const Icon = ({
  name,
  onClick,
  fontSize = 20,
  style,
  color = '#000',
  disabled,
  id,
  alt,
  ariaHidden = false,
  ariaLabel,
  tabIndex = '0',
  role,
}) => {
  const Component = fontAwesome[name] || mdIcons[name];
  const iconColor = disabled ? 'silver' : color;
  return (
    <Component
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
      tabIndex={ariaLabel ? tabIndex : -1}
      role={role}
      id={id}
      alt={alt}
      className={clsx('alf-icon', onClick && 'button', disabled && 'disabled')}
      onClick={onClick}
      style={{
        width: `${fontSize}px`,
        height: `${fontSize}px`,
        fill: iconColor,
        ...style,
      }}
    />
  );
};

Icon.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  fontSize: PropTypes.number,
  onClick: PropTypes.func,
  color: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  alt: PropTypes.string,
  ariaHidden: PropTypes.bool,
  ariaLabel: PropTypes.string,
  role: PropTypes.string,
};

export default Icon;
