/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Typography from '../Typography';
import './SideBar.css';

const Option = ({ iconName, color = 'white', title, onClick, alt }) => {
  return (
    <div className="option">
      <Icon
        name={iconName}
        color={color}
        fontSize={30}
        style={{ marginRight: 16 }}
      />
      <Typography
        alt={alt || title}
        component="h2"
        type="menuOption"
        withTab
        onClick={onClick}
        style={{
          cursor: 'pointer',
        }}
      >
        {title}
      </Typography>
    </div>
  );
};

Option.propTypes = {
  iconName: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  alt: PropTypes.string,
};

export default Option;
