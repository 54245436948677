import React from 'react';
import { withRouter } from 'react-router-dom';
import { peopleSpeak, trafficCone } from 'assets/icon';

import { Row, Col, Typography } from '../alf-design';
import { publicSlideLogo, maintenance } from '../../assets/img';
import Background from './Background';
import Footer from './Footer';

import './MaintenancePage.css';

const MaintenancePage = () => {
  const renderCircleIcon = (icon) => <img alt="" src={icon} className="icon" />;

  return (
    <Background isMobile={true}>
      <main
        id="public-main-content"
        role="main"
        style={{ minHeight: window.innerHeight - 240 }}
      >
        <Row className="maintenanceLogo">
          <img src={publicSlideLogo} alt="Logo" className="logo" />
        </Row>
        <Row className="maintenancePage">
          <Col xl={12} className="panelLeft">
            <img
              src={maintenance}
              alt="maintenanceImg"
              className="maintenanceImg"
            />
          </Col>
          <Col xl={12} className="panelRight">
            <Col xs={24} md={24} className="rightItems">
              <Col xs={5} md={5} className="circleIcon">
                {renderCircleIcon(trafficCone)}
              </Col>
              <Col xs={19} md={19}>
                <Typography
                  type="heading3"
                  component="h2"
                  className="item-subtitle"
                >
                  System Maintenance
                </Typography>
                <Typography
                  type="paragraph"
                  component="p"
                  className="item-paragraph"
                >
                  mySlide is undergoing scheduled maintenance. <br />
                  Access and tools will not be available during this time.
                </Typography>
              </Col>
              <Col xs={24} md={24} className="customerService">
                <Col xs={5} md={5} className="circleIcon">
                  {renderCircleIcon(peopleSpeak)}
                </Col>
                <Col xs={19} md={19}>
                  <Typography
                    type="heading3"
                    component="h2"
                    className="item-subtitle"
                  >
                    Customer Service
                  </Typography>
                  <Typography
                    type="paragraph"
                    component="p"
                    className="item-paragraph"
                  >
                    If you have any questions or need help with your account,
                    call customer service at (800) 748-2030. Available Monday -
                    Friday, 8am - 5pm EST
                  </Typography>
                </Col>
              </Col>
            </Col>
          </Col>
        </Row>
      </main>
      <Footer />
    </Background>
  );
};

export default withRouter(MaintenancePage);
