import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Typography.css';

const TYPES = [
  'welcome',
  'heading1',
  'heading2',
  'heading3',
  'subtitle',
  'subtitle2',
  'subtitle3',
  'paragraph',
  'caption1',
  'caption2',
  'caption3',
  'caption4',
  'caption5',
  'error',
  'menuOption',
  'menuTitle',
  'linkButton',
];
const COMPONENTS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'span',
  'label',
  'div',
  'button',
];

const Typography = ({
  id,
  component: Component = 'p',
  style,
  onClick,
  className,
  type = 'paragraph',
  children,
  withTab = false,
  htmlFor,
  alt,
  ariaLabel,
  ariaHidden,
  wordNoWrap = false,
  hidden = false,
}) => (
  <Component
    alt={alt}
    aria-label={ariaLabel}
    aria-hidden={ariaHidden}
    tabIndex={withTab ? '0' : '-1'}
    htmlFor={htmlFor}
    id={id}
    style={style}
    onClick={onClick}
    onKeyPress={(event) => {
      if (event.key === 'Enter' && onClick) {
        onClick(event);
      }
    }}
    className={clsx(
      'alf-typography',
      className,
      type,
      withTab && 'with-tab',
      wordNoWrap && 'word-nowrap',
    )}
    hidden={hidden}
  >
    {children}
  </Component>
);

Typography.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(TYPES),
  component: PropTypes.oneOf(COMPONENTS),
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  withTab: PropTypes.bool,
  alt: PropTypes.string,
  ariaLabel: PropTypes.string,
  htmlFor: PropTypes.string,
  wordNoWrap: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default Typography;
