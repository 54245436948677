import React from 'react';
import config from 'config';

import './Claims.css';
import { Typography, Button, Row, Card } from '../alf-design';

const WithoutClaims = () => {
  return (
    <Card>
      <Row gutter={16} className="withoutClaims">
        <div style={{ width: 'fit-content' }}>
          <Typography type="heading3" component="h3">
            Report a Loss
          </Typography>
          <Typography type="paragraph">
            {`To file a claim, click the button below or call Claims Services at `}
            <a
              href={`tel:866-230-3758`}
              style={{
                textDecoration: 'none',
                fontWeight: 'bold',
                color: '#6648dc',
              }}
              tabIndex="0"
            >
              (866) 230-3758.
            </a>
            <br />
            Please have your policy number ready for identification.
          </Typography>
          <Button
            id="Report-Loss-button"
            type="primary"
            onClick={() => window.open(config.fnol)}
            style={{ width: 180, marginTop: 20 }}
            link
          >
            File a Claim
          </Button>
        </div>
      </Row>
    </Card>
  );
};

export default WithoutClaims;
